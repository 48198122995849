import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "pages/Loading/Loading";
import { getPriceLists } from "services/candles-service";
import { handleErr, closeErrName } from "store/modules/error/actions";
import ReportPage from "./components/Report";
import "./styles/Account.scss";
import { MenuItem, InputLabel, FormControl, Select } from "@mui/material";
import TransferReport from "./components/TransferReport";

const REPORT_TYPE_OPTIONS = [
  { key: "trade", title: "Trade", titleLong: "Trade History Report" },
  { key: "transfer", title: "Transfer", titleLong: "Transfer History Report" },
];

const Account = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const [loading, setLoading] = useState(true);
  const [cryptoPrices, setCryptoPrices] = useState(null);

  const [reportType, setReportType] = useState("trade");

  useEffect(() => {
    if (auth.changeAccount) {
      setLoading(true);

      const fetchData = async () => {
        const res = await getPriceLists({
          exchange: auth.changeAccount.exchange,
          tenantId: userInfo?.tenantId,
          //ID_TODO: likely wont need these
          //clientAccountId: userInfo?.clientAccountId,
          timeFrames: [
            { timeFrame: "1D" },
            { timeFrame: "1W" },
            { timeFrame: "1M" },
            { timeFrame: "3M" },
            { timeFrame: "1Y" },
          ],
        });

        if (res.data.status === "success") {
          setCryptoPrices(res.data);

          dispatch(closeErrName({ name: "account-getPriceLists" }));
        } else {
          dispatch(
            handleErr({ data: res.data, name: "account-getPriceLists" })
          );
        }

        setLoading(false);
      };
      fetchData();
    }
  }, [userInfo?.exchangeIds, auth.changeAccount]);

  return (
    <div className="account-page">
      <div className="select-type-container">
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <InputLabel
            sx={{ color: "white", "&.Mui-focused": { color: "white" } }}
          >
            Report
          </InputLabel>
          <Select
            value={reportType}
            label="Report"
            name="report"
            onChange={(e) => setReportType(e.target.value)}
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              ".MuiSvgIcon-root ": {
                fill: "white !important",
              },
            }}
          >
            {REPORT_TYPE_OPTIONS.map(({ key, title }) => (
              <MenuItem key={key} value={key}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <label className="title">
          {REPORT_TYPE_OPTIONS.find(({ key }) => key === reportType).titleLong}
        </label>
      </div>
      {!loading && cryptoPrices ? (
        reportType === "trade" ? (
          <ReportPage cryptoPrices={cryptoPrices} />
        ) : (
          <TransferReport />
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Account;
