import React from "react";
import Routes from './Routes';

const App = () => {

  return (
    <Routes />
  );
};

export default App;