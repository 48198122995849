import axios from "axios";
import { getToken } from "./token-service.js";
import { env } from "utils/globals.js";
import { errorCustom } from "utils/errorCustom.js";

export const getTransfersByExchange = async (exchange) => {
  try {
    return await axios.post(
      `${env.TRADE_URL}/transfer/${exchange}`,
      {},
      { headers: { Authorization: `Bearer ${getToken()}` } }
    );
  } catch (err) {
    return errorCustom(err);
  }
};
