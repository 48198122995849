import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import {
  SortingState,
  IntegratedSorting,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  SummaryState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  DragDropProvider,
  TableColumnVisibility,
  TableGroupRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  InputLabel,
  FormControl,
  Select,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Moment from "moment";
import { extendMoment } from "moment-range";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import { handleErr } from "store/modules/error/actions";
import "../styles/TransferReport.scss";
import Popup from "reactjs-popup";
import { getTransfersByExchange } from "services/transfer-service";

const moment = extendMoment(Moment);

const PERIOD_OPTIONS = [
  { key: "this-week", title: "This Week" },
  { key: "last-week", title: "Last Week" },
  { key: "this-month", title: "This Month" },
  { key: "last-month", title: "Last Month" },
  { key: "this-quarter", title: "This Quarter" },
  { key: "last-quarter", title: "Last Quarter" },
  { key: "this-year", title: "This Year" },
  { key: "last-year", title: "Last Year" },
  { key: "all-years", title: "All Years" },
];

const HIDDEN_COLUMNS = ["month", "year", "time"];

const baseColumns = [
  { name: "exchangeAccountName", title: "Account Name" },
  { name: "exchange", title: "Exchange" },
  { name: "transCurrency", title: "Currency" },
  { name: "transType", title: "Type" },
  { name: "transReason", title: "Reason" },
  { name: "transAmount", title: "Amount" },
  { name: "transMode", title: "Mode" },
  { name: "transStatus", title: "Status" },
  { name: "adminUserName", title: "Admin User" },
  { name: "transMessage", title: "Message" },
  { name: "transError", title: "Error" },
  { name: "transDesc", title: "Other Reason" },
];

const COLUMNS = [
  { name: "date", title: "DateTime" },
  { name: "year", title: "Year" },
  { name: "month", title: "Month" },
  ...baseColumns,
];

const CSV_HEADERS = [
  { key: "date", label: "Date" },
  { key: "time", label: "Time" },
  { key: "clientAccountId", label: "Account ID" },
  ...baseColumns.map(({ name, title }) => ({ key: name, label: title })),
];

const GROUPING_COLUMN_EXTENSIONS = [
  { columnName: "adminUserName", groupingEnabled: false },
  { columnName: "clientAccountId", groupingEnabled: false },
  { columnName: "transDesc", groupingEnabled: false },
  { columnName: "transError", groupingEnabled: false },
  { columnName: "transMessage", groupingEnabled: false },
  { columnName: "transAmount", groupingEnabled: false },
];

const GROUP_SUMMARY_ITEMS = [
  {
    columnName: "transAmount",
    type: "sum",
    showInGroupFooter: false,
    alignByColumn: true,
  },
];

const GROUP_NONE = 0;
const GROUP_CURRENCY = 1;
const GROUP_STATUS = 2;
const GROUP_TYPE = 3;
const GROUP_REASON = 4;

const GROUP_OPTIONS = [
  {
    key: GROUP_NONE,
    title: "None",
    groups: [
      { columnName: "year" },
      { columnName: "month" },
      { columnName: "date" },
    ],
  },
  {
    key: GROUP_CURRENCY,
    title: "Currency",
    groups: [
      { columnName: "year" },
      { columnName: "month" },
      { columnName: "date" },
      { columnName: "transCurrency" },
    ],
  },
  {
    key: GROUP_STATUS,
    title: "Status",
    groups: [
      { columnName: "year" },
      { columnName: "month" },
      { columnName: "date" },
      { columnName: "transStatus" },
    ],
  },
  {
    key: GROUP_TYPE,
    title: "Transfer type",
    groups: [
      { columnName: "year" },
      { columnName: "month" },
      { columnName: "date" },
      { columnName: "transType" },
    ],
  },
  {
    key: GROUP_REASON,
    title: "Transfer Reason",
    groups: [
      { columnName: "year" },
      { columnName: "month" },
      { columnName: "date" },
      { columnName: "transReason" },
    ],
  },
];

// const DATE_GROUP_NONE = 0;
const DATE_GROUP_DATE = 1;
const DATE_GROUP_MONTH = 2;
const DATE_GROUP_YEAR = 3;

const DATE_GROUP_OPTIONS = [
  // { key: DATE_GROUP_NONE, title: "None" },
  { key: DATE_GROUP_DATE, title: "Date" },
  { key: DATE_GROUP_MONTH, title: "Month" },
  { key: DATE_GROUP_YEAR, title: "Year" },
];

const stylesTransfer = {
  year: {
    backgroundColor: "#379EFB",
  },
  month: {
    backgroundColor: "#61B3FC",
  },
  date: {
    backgroundColor: "#9DCFFD",
  },
  transCurrency: {
    backgroundColor: "#CAF7C4",
  },
  transType: {
    backgroundColor: "#F8C6C2",
  },
  transReason: {
    backgroundColor: "#FAC0FA",
  },
  transStatus: {
    backgroundColor: "#FAC0FA",
  },
};

const stylesTransferId = {
  year: {
    backgroundColor: "#0475DB",
    color: "#000000",
  },
  month: {
    backgroundColor: "#0485F9",
    color: "#000000",
  },
  date: {
    backgroundColor: "#2696FB",
    color: "#000000",
  },
  transCurrency: {
    backgroundColor: "#50E43A",
    color: "#000000",
  },
  transType: {
    backgroundColor: "#EC665B",
    color: "#000000",
  },
  transReason: {
    backgroundColor: "#F25EF2",
    color: "#000000",
  },
  transStatus: {
    backgroundColor: "#F25EF2",
    color: "#000000",
  },
};

const TableHeaderCell = (props) => {
  const {
    style,
    column,
    getCellWidth,
    draggingEnabled,
    onWidthChange,
    onWidthDraft,
    onWidthDraftCancel,
    resizingEnabled,
    tableColumn,
    tableRow,
    ...restProps
  } = props;

  return (
    <Table.Cell
      {...restProps}
      style={{
        ...style,
        ...(column.name === "date" && { minWidth: 110 }),
        cursor: "pointer",
        fontWeight: "500",
      }}
    >
      <span>{column.title}</span>
    </Table.Cell>
  );
};
const TableCell = (props) => {
  const { style, column, value, row, ...restProps } = props;
  return (
    <Table.Cell
      {...restProps}
      style={{
        ...style,
        paddingTop: 5,
        paddingBottom: 5,
        // minWidth: 200,
        // width: "auto",
        // ...(column.name === "date" && { minWidth: 200 }),
      }}
    >
      <span>
        {column.name === "transAmount" && row.transType === "debit" ? (
          <span style={{ color: "red", fontWeight: "bold" }}>-{value}</span>
        ) : column.name === "transAmount" && row.transType === "credit" ? (
          <span style={{ color: "green", fontWeight: "bold" }}>{value}</span>
        ) : column.name === "transAmount" && `${value}`.includes("e") ? (
          value.toFixed(8)
        ) : column.name === "date" ? (
          moment(row.datetime).format("YYYY-MM-DD hh:mm:ss A")
        ) : (
          value
        )}
      </span>
    </Table.Cell>
  );
};

const GroupTableRow = (props) => {
  const { row, column, expanded, ...restProps } = props;
  console.log("Row @@@@", row);
  return (
    <Table.Row
      {...restProps}
      style={{
        cursor: "pointer",
        height: 0,
        ...stylesTransfer[String(row.groupedBy)],
      }}
    />
  );
};

const GroupTableCell = (props) => {
  const {
    style,
    colSpan,
    row,
    column,
    expanded,
    onToggle,
    classes,
    children,
    className,
    tableRow,
    tableColumn,
    inlineSummaries,
    contentComponent,
    iconComponent,
    containerComponent,
    inlineSummaryComponent,
    inlineSummaryItemComponent,
    getMessage,
    grouping,
    data,
    ...restProps
  } = props;

  const handleClick = () => onToggle();

  return (
    <Table.Cell
      {...restProps}
      colSpan={colSpan}
      style={{ ...style, paddingTop: 0, paddingBottom: 0 }}
      onClick={handleClick}
    >
      <IconButton size="small">
        {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      </IconButton>
      <span
        style={{
          color: stylesTransferId[column.name].color,
          background: stylesTransferId[column.name].backgroundColor,
          padding: "2px 15px",
          borderRadius: "15px",
        }}
      >
        {children || column.name === "date" ? "Date" : column.title}
      </span>
      <span
        className="text-capitalize"
        style={{
          marginLeft: "15px",
          fontWeight: "bold",
          color: stylesTransferId[column.name].color,
        }}
      >
        {children || row.groupedBy === "date"
          ? moment(row.value).format("YYYY-MM-DD")
          : row.value}
      </span>
    </Table.Cell>
  );
};

const TransferReport = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const [allRecords, setAllRecords] = useState();

  const [grouping, setGrouping] = useState([]);
  const [expandedGroup, setExpandedGroup] = useState([]);

  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));

  const [startDateOfRange, setStartDateOfRange] = useState(
    moment().startOf("day")
  );
  const [endDateOfRange, setEndDateOfRange] = useState(moment().endOf("day"));

  const [selectedGroup, setSelectedGroup] = useState(GROUP_OPTIONS[0].key);
  const [selectedDateGroup, setSelectedDateGroup] = useState(
    DATE_GROUP_OPTIONS[0].key
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [sorting, setSorting] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("all-years");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    if (auth.changeAccount && userInfo) {
      fetchTransfers();
    }
  }, [auth.changeAccount, userInfo]);

  useEffect(() => {
    let start = null;
    let end = null;
    switch (selectedPeriod) {
      case "this-week":
        start = moment().isoWeekday(1).startOf("day");
        end = moment().isoWeekday(7).endOf("day");
        break;
      case "last-week":
        start = moment().isoWeekday(-6).startOf("day");
        end = moment().isoWeekday(0).endOf("day");
        break;
      case "this-month":
        start = moment().startOf("month");
        end = moment().endOf("month");
        break;
      case "last-month":
        start = moment().subtract(1, "months").startOf("month");
        end = moment().subtract(1, "months").endOf("month");
        break;
      case "this-quarter":
        start = moment().startOf("quarter");
        end = moment().endOf("quarter");
        break;
      case "last-quarter":
        start = moment().subtract(1, "quarters").startOf("quarter");
        end = moment().subtract(1, "quarters").endOf("quarter");
        break;
      case "this-year":
        start = moment().startOf("year");
        end = moment().endOf("year");
        break;
      case "last-year":
        start = moment().subtract(1, "years").startOf("year");
        end = moment().subtract(1, "years").endOf("year");
        break;
      default:
        start = moment("1970-01-01").startOf("year");
        end = moment("2099-12-31").endOf("year");
        break;
    }

    if (start && end) {
      setStartDate(start);
      setEndDate(end);

      setStartDateOfRange(start);
      setEndDateOfRange(end);
    }
  }, [selectedPeriod]);

  const handleApplyClick = () => {
    setStartDate(startDateOfRange);
    setEndDate(endDateOfRange);
  };

  const fetchTransfers = async () => {
    try {
      const {
        data: { status, data: result, message },
      } = await getTransfersByExchange(auth.changeAccount.exchange);
      if (status === "success") {
        setAllRecords(
          result
            .filter((item) => item.transReason !== "fee")
            .map((item) => ({
              ...item,
              date: `${moment(item.datetime).format("YYYY-MM-DD")}`,
              month: `${moment(item.datetime).format("YYYY-MM")}`,
              year: `${moment(item.datetime).format("YYYY")}`,
              time: `${moment(item.datetime).format("hh:mm:ss A")}`,
            }))
        );
      } else {
        dispatch(handleErr({ data: { status: "Failed", message } }));
      }
    } catch (error) {
      dispatch(handleErr({ data: { status: "Failed", message: error } }));
    }
  };

  const expandedGroupsChange = (expandedGroups) => {
    setExpandedGroup(expandedGroups);
  };

  useEffect(() => {
    setupGrouping();
  }, [selectedDateGroup, selectedGroup]);

  useEffect(() => {
    if (!allRecords || !allRecords.length) {
      return;
    }

    const records = allRecords.filter((record) => {
      // filter by period
      if (startDate && endDate) {
        const range = moment.range(startDate, endDate);
        if (!range.contains(moment(record.date))) {
          return false;
        }
      }

      return true;
    });

    setFilteredRecords(records);
  }, [allRecords, startDate, endDate]);

  const setupGrouping = () => {
    const groups = GROUP_OPTIONS.find(
      ({ key }) => key === selectedGroup
    ).groups;

    switch (selectedDateGroup) {
      case DATE_GROUP_DATE:
        setGrouping(
          groups.filter(
            ({ columnName }) => !["month", "year"].includes(columnName)
          )
        );
        break;
      case DATE_GROUP_MONTH:
        setGrouping(groups.filter(({ columnName }) => columnName !== "year"));
        break;
      case DATE_GROUP_YEAR:
        setGrouping(groups);
        break;
      default:
        setGrouping(
          groups.filter(
            ({ columnName }) => !["date", "month", "year"].includes(columnName)
          )
        );
        break;
    }
  };

  const expandClick = () => {
    if (expandedGroup.length === 0) {
      const dateUnique = filteredRecords
        .map((a) => a.date)
        .filter((value, index, self) => self.indexOf(value) === index);
      const monthUnique = filteredRecords
        .map((a) => a.month)
        .filter((value, index, self) => self.indexOf(value) === index);
      const yearUnique = filteredRecords
        .map((a) => a.year)
        .filter((value, index, self) => self.indexOf(value) === index);
      const transCurrencyUnique = filteredRecords
        .map((a) => a.transCurrency)
        .filter((value, index, self) => self.indexOf(value) === index);
      const transTypeUnique = filteredRecords
        .map((a) => a.transType)
        .filter((value, index, self) => self.indexOf(value) === index);
      const statusUnique = filteredRecords
        .map((a) => a.transStatus)
        .filter((value, index, self) => self.indexOf(value) === index);
      const reasonUnique = filteredRecords
        .map((a) => a.transReason)
        .filter((value, index, self) => self.indexOf(value) === index);

      const initData = {
        date: dateUnique,
        month: monthUnique,
        year: yearUnique,
        transCurrency: transCurrencyUnique,
        transType: transTypeUnique,
        transStatus: statusUnique,
        transReason: reasonUnique,
      };

      const cusGrouping = grouping.map((item) => item.columnName);
      const mergeData = generateExpandedGroup(initData, cusGrouping);

      setExpandedGroup(mergeData);
    } else {
      setExpandedGroup([]);
    }
  };

  const generateExpandedGroup = (data, groups) => {
    const result = [];
    groups.forEach((group) => {
      result.push(data[group]);
    });
    return combineArrays(result);
  };

  const combineArrays = (arrays) => {
    if (arrays.length === 0) {
      return [];
    }

    if (arrays.length === 1) {
      return arrays[0];
    }

    const [firstArray, ...rest] = arrays;
    const combinationsOfRest = combineArrays([...rest]);

    let result = [];

    for (let item of firstArray) {
      result.push(item);

      for (let combo of combinationsOfRest) {
        result.push(item + "|" + combo);
      }
    }

    return result;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportSummary = () => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return;
    }
    const scrollWidth = tableContainerRef.current.scrollWidth;

    const report = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [scrollWidth, 1200],
    });

    let periodText = `Period: ${
      PERIOD_OPTIONS.find((p) => p.key === selectedPeriod).title
    } (${startDate.format("YYYY-MM-DD")} - ${endDate.format("YYYY-MM-DD")})`;

    let summaryText = `Date Summary: ${
      DATE_GROUP_OPTIONS.find((p) => p.key === selectedDateGroup).title
    }`;

    let groupText = `Grouping: ${
      GROUP_OPTIONS.find((p) => p.key === selectedGroup).title
    }`;

    report.setFontSize(20);
    report.text(50, 35, periodText);
    report.text(50, 60, summaryText);
    report.text(50, 85, groupText);

    const opt = {
      margin: [90, 0, 50, 0],
    };

    report.html(document.querySelector(".Table-table"), opt).then(() => {
      //ID_TODO: seems like this Id may be better served by the exchangeID
      const fileName =
        "Transfer History" + moment().format("YYYY-MM-DD") + ".pdf";

      report.save(fileName);

      handleClose();
    });
  };

  const renderSummaryCell = (props) => {
    const { style, ...restProps } = props;
    const value = restProps.children.props.columnSummaries[0].value;
    let valueString = "";
    if (
      selectedGroup !== GROUP_CURRENCY ||
      !["year", "month", "date"].includes(restProps.row.groupedBy)
    ) {
      valueString = `${value}`.includes("e")
        ? value.toFixed(8)
        : +value.toFixed(8);
    }

    return (
      <Table.Cell
        style={{ ...style, paddingTop: 0, paddingBottom: 0 }}
        {...restProps}
      >
        <span className={`summary_item ${+value < 0 ? "minus" : ""}`}>
          {valueString}
        </span>
      </Table.Cell>
    );
  };

  return (
    <div className={"reporting_page"}>
      <div className={"date-filters"}>
        <div className={"date-period"}>
          {PERIOD_OPTIONS.map(({ key, title }, index) => (
            <label htmlFor={`radio-${key}`} key={index}>
              <input
                type="radio"
                className={"range"}
                id={`radio-${key}`}
                value={key}
                readOnly
                checked={selectedPeriod === key}
                onChange={(e) => setSelectedPeriod(e.target.value)}
              />
              <div className={"range"}>{title}</div>
            </label>
          ))}
        </div>
        <div className={"date-range"}>
          <Popup
            trigger={
              <div className={"start-date"}>
                <span>{startDateOfRange.format("MMM-DD-YYYY")}</span>
              </div>
            }
            open={false}
            arrow={false}
            position={["center right"]}
            closeOnDocumentClick
          >
            <input
              type="date"
              id="start"
              name="trip-start"
              value={startDateOfRange.format("YYYY-MM-DD")}
              onChange={(e) => setStartDateOfRange(moment(e.target.value))}
            ></input>
          </Popup>
          <div>
            <span>&nbsp;-&nbsp;</span>
          </div>
          <Popup
            trigger={
              <div className={"end-date"}>
                <span>{endDateOfRange.format("MMM-DD-YYYY")}</span>
              </div>
            }
            arrow={false}
            open={false}
            position={["center right"]}
            closeOnDocumentClick
          >
            <input
              type="date"
              id="start"
              name="trip-end"
              value={endDateOfRange.format("YYYY-MM-DD")}
              min={startDateOfRange.format("YYYY-MM-DD")}
              onChange={(e) => setEndDateOfRange(moment(e.target.value))}
            ></input>
          </Popup>
          <Button
            className={"apply-btn"}
            variant="contained"
            onClick={handleApplyClick}
          >
            Apply
          </Button>
        </div>
      </div>
      <div className={"card transfer-report"}>
        {allRecords ? (
          <Grid rows={filteredRecords} columns={COLUMNS}>
            <DragDropProvider />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <GroupingState
              grouping={grouping}
              onGroupingChange={setGrouping}
              columnExtensions={GROUPING_COLUMN_EXTENSIONS}
              expandedGroups={expandedGroup}
              onExpandedGroupsChange={expandedGroupsChange}
            />
            <SummaryState groupItems={GROUP_SUMMARY_ITEMS} />
            <IntegratedSorting />
            <IntegratedGrouping />
            <IntegratedSummary
              calculator={(_, rows) => {
                const sum = rows
                  .filter((row) => row.transStatus === "complete")
                  .map((row) => row.transAmount)
                  .reduce((prev, cur) => prev + cur, 0);
                return sum;
              }}
            />
            <Table
              cellComponent={(props) => <TableCell {...props} />}
              containerComponent={(props) => (
                <div
                  {...props}
                  className={"reporting_table"}
                  ref={tableContainerRef}
                ></div>
              )}
              headComponent={(props) => (
                <thead {...props} className={"table_head"}></thead>
              )}
            />
            <TableColumnVisibility hiddenColumnNames={HIDDEN_COLUMNS} />
            <TableGroupRow
              rowComponent={GroupTableRow}
              cellComponent={(props) => (
                <GroupTableCell
                  {...props}
                  grouping={grouping}
                  data={filteredRecords}
                />
              )}
              summaryCellComponent={renderSummaryCell}
              showColumnsWhenGrouped
            />

            <Toolbar />
            <TableHeaderRow cellComponent={TableHeaderCell} />
            <Template name="toolbarContent">
              <TemplatePlaceholder />
              <div className={"control-bar"}>
                <div className={"control-bar--group--date"}>
                  <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <InputLabel id="demo-select-small">Date Summary</InputLabel>
                    <Select
                      value={selectedDateGroup}
                      label="Date Summary"
                      name="group-date"
                      onChange={(e) => setSelectedDateGroup(e.target.value)}
                    >
                      {DATE_GROUP_OPTIONS.map(({ key, title }) => (
                        <MenuItem key={key} value={key}>
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="control-bar--group">
                  <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <InputLabel id="demo-select-small">Grouping</InputLabel>
                    <Select
                      value={selectedGroup}
                      label="Grouping"
                      name="group"
                      onChange={(e) => setSelectedGroup(e.target.value)}
                    >
                      {GROUP_OPTIONS.map(({ key, title }) => (
                        <MenuItem key={key} value={key}>
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <Button
                  className={"expand-btn"}
                  variant="contained"
                  onClick={expandClick}
                >
                  {expandedGroup.length === 0 ? "Expand all" : "Collapse all"}
                </Button>
                <Button
                  className={"expand-btn"}
                  variant="contained"
                  onClick={handleClick}
                >
                  Export
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleExportSummary}>
                    Export Summary
                  </MenuItem>
                  <MenuItem>
                    <CSVLink
                      filename={`Transfer History Report ${moment().format(
                        "YYYY-MM-DD hh-mm-ss A"
                      )}.csv`}
                      data={filteredRecords}
                      headers={CSV_HEADERS}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Export Detail
                    </CSVLink>
                  </MenuItem>
                </Menu>
              </div>
            </Template>
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "50px 50px",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransferReport;
